import React from 'react';
import ReactDOM from 'react-dom/client';
import { ApplicationProvider } from '@sm360/apps-core';
import packageJson from '../package.json';
import NotAuthenticated from './components/NotAuthenticated';
import App from './App';

const container = document.getElementById('root');
const root = ReactDOM.createRoot(container);

root.render(
    <ApplicationProvider
        app={<App />}
        packageJson={packageJson}
        components={{
            NotAuthenticated: NotAuthenticated,
        }}
    />,
);
