import React from 'react';
import { AppContext, useTranslation } from '@sm360/apps-core';

const AppLogo = () => {
    const {
        state: { industry },
    } = React.useContext(AppContext);
    const { locale } = useTranslation();
    return (
        <>
            <img src={`/img/logo-${industry}-${locale}.svg`} alt="LogoText" className="brand-logo" />
            <img src={`/img/logo-${industry}-${locale}.svg`} alt="LogoText" className="brand-logo--mobile" />
            <img src={`/img/logo-mobile-${industry}-${locale}.svg`} alt="LogoImage" className="brand-logo--collapsed" />
        </>
    );
};

export default AppLogo;
