import React from 'react';
import { AppWrapper, AppContext, useTranslation } from '@sm360/apps-core';
import * as i18nRessources from '@sm360/apps-i18n';
import { ReactComponent as SvgSprite } from '@sm360/ui-kijiji/dist/assets/svg-sprite.svg';
import packageJson from '../package.json';
import NavList from './navigation';
import AppLogo from './components/Logo';
import AppLanding from './components/AppLanding';
import UserPanel from './components/UserPanel';
import Tracking from './components/Tracking';

const App = () => {
    const { t } = useTranslation();
    const { state } = React.useContext(AppContext);
    const [ready, setReady] = React.useState(false);

    React.useEffect(() => {
        if (state?.industry === 'pv') {
            import('@sm360/ui-kijiji/dist/css/pv/kijiji-autos.css').then(() => setReady(true));
        } else if (state?.industry === 'npv') {
            import('@sm360/ui-kijiji/dist/css/npv/kijiji.css').then(() => setReady(true));
        }
    }, [state]);

    return (
        <AppWrapper
            ready={ready}
            autoUpdate
            defaultTitle={`Kijiji ${t('app.NAME')}`}
            titleTemplate={`Kijiji ${t('app.NAME')} - %s`}
            packageJson={packageJson}
            appLogo={AppLogo}
            appLanding={AppLanding}
            userPanel={UserPanel}
            appNavList={NavList.NavOrganization}
            svgSprite={<SvgSprite />}
            accessRight="XmsAccess"
            withPromo={false}
            withSupport={false}
            i18nRessources={i18nRessources}
        >
            <Tracking organization={state.organization} user={state.user} name={packageJson.name} />
        </AppWrapper>
    );
};

export default App;
