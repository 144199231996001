import React from 'react';
import { useTranslation, LangSwitcher } from '@sm360/apps-core';
import { Button } from '@sm360/ui-library';
import './NotAuthenticated.css';

const NotAuthenticated = () => {
    const { t } = useTranslation();
    const queryString = window.location.search;

    const urlParams = new URLSearchParams(queryString);

    const messageText = urlParams.get('message');

    return (
        <div className="uk-landing">
            <div className="uk-landing__tools">
                <LangSwitcher />
            </div>
            <p>{t(`authentication.${messageText}`)}</p>
            <Button
                color="white"
                outline
                className="margin-top-1"
                onClick={() => window.location.assign(window.location.origin)}
                label={t('commons.actions.RETRY')}
            />
        </div>
    );
};

export default NotAuthenticated;
