import React from 'react';

const Imports = React.lazy(() => import('@sm360/imports'));
const ImportTypes = React.lazy(() => import('@sm360/import-types'));
const ImportDashboards = React.lazy(() => import('@sm360/import-dashboards'));
const Exports = React.lazy(() => import('@sm360/kijiji-exports'));
const Bulks = React.lazy(() => import('@sm360/bulks'));
const Dealers = React.lazy(() => import('@sm360/kijiji-dealers'));
// const Inventories = React.lazy(() => import('@sm360/kijiji-inventories'));
const Inventories = React.lazy(() => import('@sm360/kijiji-inventories'));
const InventoriesOperations = React.lazy(() => import('@sm360/kijiji-inventories-operations'));
const Reports = React.lazy(() => import('@sm360/kijiji-reports'));
const DownloadCenter = React.lazy(() => import('@sm360/download-center'));
const Users = React.lazy(() => import('@sm360/kijiji-users'));
const ThirdPartyDealer = React.lazy(() => import('@sm360/thirdparty-dealer'));
const ThirdPartyDealerPush = React.lazy(() => import('@sm360/thirdparty-dealer-push'));
const ThirdPartyDealerPushHistory = React.lazy(() => import('@sm360/thirdparty-dealer-push-history'));

const NavOrganization = [
    {
        heading: 'app.nav.ADMINISTRATION',
        heading_short: 'app.nav.ADMINISTRATION_SHORT',
    },
    {
        name: 'Inventories',
        translate: 'app.nav.INVENTORIES',
        route: '/inventories/vehicles',
        right: 'XmsDeltaVehicles',
        icon: 'car',
        description: 'app.nav.VEHICLES_DESCRIPTION',
        component: Inventories,
    },
    {
        name: 'Imports',
        translate: 'app.nav.IMPORTS',
        route: '/vehicles/imports/imports',
        right: 'XmsDeltaImports',
        icon: 'file-import',
        description: 'app.nav.IMPORTS_DESCRIPTION',
        component: Imports,
    },
    {
        name: 'Exports',
        translate: 'app.nav.EXPORTS',
        route: '/exports',
        right: 'XmsDeltaImports',
        icon: 'file-export',
        description: 'app.nav.EXPORTS_DESCRIPTION',
        component: Exports,
    },
    {
        name: 'Users',
        translate: 'app.nav.USERS',
        route: '/administration/users',
        icon: 'user-friends',
        right: 'UserManagement',
        description: 'app.nav.USERS_DESCRIPTION',
        component: Users,
    },
    {
        name: 'Dealers',
        translate: 'app.nav.DEALERS',
        route: '/dealers',
        right: 'XmsDeltaDealers',
        icon: 'store',
        description: 'app.nav.DEALERS_DESCRIPTION',
        component: Dealers,
    },
    {
        name: 'Reporting',
        translate: 'app.nav.REPORTS',
        route: '/tools/reporting',
        right: 'XmsDeltaVehicles',
        icon: 'clipboard',
        description: 'app.nav.REPORTS_DESCRIPTION',
        component: Reports,
    },

    {
        heading: 'app.nav.OPERATIONS',
        heading_short: 'app.nav.OPERATIONS_SHORT',
    },
    {
        name: 'Dashboards',
        translate: 'app.nav.IMPORTS_DASHBOARD',
        route: '/vehicles/imports/dashboards',
        right: 'XmsDeltaImportDashboard',
        icon: 'clipboard',
        description: 'app.nav.IMPORTS_DASHBOARD_DESCRIPTION',
        component: ImportDashboards,
    },
    {
        name: 'Inventory Operation',
        translate: 'app.nav.INVENTORY_OPERATIONS',
        route: '/operations/inventory',
        right: 'XmsAdministrationAccess',
        icon: 'car',
        description: 'Inventory Operation',
        component: InventoriesOperations,
    },
    // {
    //     name: 'Imports Configuration',
    //     translate: 'app.nav.IMPORTS_CONFIGURATION',
    //     right: 'XmsAdministrationAccess',
    //     icon: 'file-import',
    //     submenu: [
    {
        name: 'Import Types',
        translate: 'app.nav.IMPORT_TYPES',
        route: '/vehicles/imports/import-types',
        right: 'XmsDeltaImportTypes',
        icon: 'cog',
        description: 'app.nav.IMPORT_TYPES_DESCRIPTION',
        component: ImportTypes,
    },

    //     ],
    // },
    {
        name: 'Thirdparty Configurations',
        translate: 'app.nav.THIRDPARTY_CONFIGURATION',
        icon: 'cogs',
        description: 'Thirdparty Configurations',
        right: 'XmsAdministrationAccess',
        submenu: [
            {
                name: 'Third Party Dealers',
                translate: 'app.nav.THIRDPARTY_DEALER',
                route: '/third-party-dealer',
                icon: 'cog',
                description: 'app.nav.THIRDPARTY_DEALER_DESCRIPTION',
                component: ThirdPartyDealer,
            },
            {
                name: 'Third Party Dealers Push',
                translate: 'app.nav.THIRDPARTY_PUSH',
                route: '/third-party-dealer-push',
                icon: 'cog',
                description: 'app.nav.THIRDPARTY_PUSH_DESCRIPTION',
                component: ThirdPartyDealerPush,
            },
            {
                name: 'Third Party Dealers Push History',
                translate: 'app.nav.THIRDPARTY_PUSH_HISTORY',
                route: '/third-party-dealer-push-history',
                icon: 'cog',
                description: 'app.nav.THIRDPARTY_PUSH_HISTORY_DESCRIPTION',
                component: ThirdPartyDealerPushHistory,
            },
        ],
    },
    {
        name: 'Bulk Inserts',
        translate: 'app.nav.BULKINSERTS',
        route: '/bulks',
        right: 'XmsDeltaBulkInserts',
        icon: 'folder-open',
        description: 'Bulk Inserts',
        component: Bulks,
    },

    {
        heading: 'app.nav.DOCUMENTS',
        heading_short: 'app.nav.DOCUMENTS_SHORT',
    },

    {
        name: 'Export Reports',
        translate: 'app.nav.DOWNLOADS',
        route: '/tools/export-reports',
        right: 'XmsDeltaVehicles',
        icon: 'file-download',
        description: 'app.nav.DOWNLOADS_DESCRIPTION',
        component: DownloadCenter,
    },
];

const NavAdmin = [];

const Nav = { NavOrganization, NavAdmin };
export default Nav;
