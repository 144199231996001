import React from 'react';

import {
    useTranslation,
    useAuth,
    useMultipleCheckRights,
    useGravatarPicture,
    LangSwitcher,
    ChangePassword,
    ResetQueryCacheButton,
} from '@sm360/apps-core';
import { Drawer, CheckboxGroup, Button, Icon, Avatar, Img, Toast, Input, AccTabs } from '@sm360/ui-library';

import packageJson from '../../package.json';

const Trigger = ({ organization, user, picture }) => {
    const kijijiRefId = 878;
    const letterStyle = {
        width: '1em',
        height: 'auto',
        position: 'absolute',
        top: '0.5rem',
        left: '0.5rem',
    };

    const superAdminTools = user.rights.filter((r) => r === 'XmsDeltaImportTypes');
    const path = organization.parentOrganizationPath ? organization.parentOrganizationPath.split('/') : [];
    const isKijijiOnly = path.includes(String(kijijiRefId)) || organization.id === kijijiRefId;

    if (isKijijiOnly) {
        return (
            <div className="grid-x align-middle">
                <div className="cell shrink">
                    <Avatar imageUrl={picture} />
                    {/* <Img src={picture} style={{ maxHeight: '1.25rem', width: 'auto', borderRadius: '100%' }} /> */}
                    {/* <Icon name="user-circle" /> */}
                </div>
                <small className="cell shrink margin-left-1 text-left hide-for-small-only">
                    {`${user.firstname} ${user.lastname}`}
                    <br />
                    <small>{organization.name}</small>
                </small>
            </div>
        );
    }
    return (
        <div className="grid-x align-middle">
            <div className="cell shrink" style={{ position: 'relative' }}>
                <Img src={picture} style={{ maxHeight: '1.25rem', width: 'auto', borderRadius: '100%' }} />
                {superAdminTools.length > 0 && (
                    <div style={letterStyle}>
                        <img src="/img/xms.png" alt="" />
                    </div>
                )}
            </div>
            <small className="cell shrink margin-left-1 text-left hide-for-small-only">
                {`${user.firstname} ${user.lastname}`}
                <br />
                <small>{organization.name}</small>
            </small>
        </div>
    );
};

const PrefPanel = ({ orgId }) => {
    const { t } = useTranslation();
    const { authUser } = useAuth();
    const [toClear, setToClear] = React.useState([]);
    const LS = JSON.parse(localStorage.getItem(`${authUser.profile.sub}@${orgId}`));

    const handleDelete = () => {
        const newPrefs = { ...LS };
        for (let index = 0; index < toClear.length; index++) {
            const item = toClear[index];
            const shortItem = item.split('--')[1];
            delete newPrefs[shortItem];
        }
        localStorage.setItem(`${authUser.profile.sub}@${orgId}`, JSON.stringify(newPrefs));
        window.location.reload();
    };

    return (
        <div className="uk-form-template">
            <CheckboxGroup
                className="uk-form-field"
                onChange={(v) => setToClear(v)}
                checked={toClear}
                options={Object.keys(LS)
                    .filter((item) => {
                        const orgRef = item.split('@')[1];
                        return Number(orgRef) === Number(orgId) || !orgRef;
                    })
                    .map((key) => {
                        const keyRef = key.split('@')[0];
                        return {
                            id: 'userPref--' + key,
                            value: key,
                            label: keyRef || key,
                        };
                    })
                    .sort((a, b) => a.label.localeCompare(b.label))}
            />
            <Button
                onClick={handleDelete}
                size="small"
                outline
                label={t('Delete selected preferences')}
                disabled={toClear.length < 1}
            />
        </div>
    );
};

const UserPanel = ({ context, name }) => {
    const { t } = useTranslation();
    const { authUser, logout } = useAuth();
    const { state, dispatch } = React.useContext(context);
    const userPicture = useGravatarPicture(state.user.email);
    const userRights = useMultipleCheckRights(state.user, ['AccountManager', 'Admin']);

    const copyToClipboard = async (authUser) => {
        if (!navigator.clipboard || !authUser) return;

        try {
            await navigator.clipboard.writeText(authUser.id_token);
            Toast.toast.success(t('app.user.TOKEN_COPY_SUCCESS'));
        } catch (error) {
            Toast.toast.error(t('app.user.TOKEN_COPY_ERROR') + error);
        }
    };

    return (
        <div className="uk-topbar__user" style={{ padding: 0 }}>
            <Drawer
                trigger={
                    <Button className="text-left" displayAsLink color="white" size="large">
                        <Trigger user={state.user} organization={state.organization} picture={userPicture.url} />
                    </Button>
                }
                className="user__drawer"
                tabIndex={3}
            >
                <>
                    <div className="user-info">
                        <div className="user-info__head">
                            <div className="user-info__avatar">
                                <Avatar imageUrl={userPicture.url} />
                            </div>
                            {(userRights?.AccountManager || userRights?.Admin) && <ResetQueryCacheButton />}
                            <LangSwitcher size="small" />
                            <Button color="white" size="small" outline onClick={logout}>
                                <i className="fa fa-sign-out-alt" />
                            </Button>
                        </div>
                        <div className="user-info__body">
                            <div className="user-info__base">
                                {authUser && (
                                    <div className="user-info__avatar">
                                        <strong>
                                            {authUser.profile.firstname} {authUser.profile.lastname}
                                        </strong>
                                    </div>
                                )}
                                <div className="user-info__organization organization">
                                    <p className="organization__legal-name">{state.organization.name}</p>
                                    <Button
                                        color="primary"
                                        displayAsLink
                                        size="small"
                                        className="organization__select"
                                        onClick={() => dispatch({ type: 'unsetOrganization' })}
                                    >
                                        {t('app.user.CTA_CHANGE_ORGANIZATION')}
                                    </Button>
                                </div>
                            </div>
                            <AccTabs className="user-info__expander">
                                <div
                                    trigger={
                                        <div>
                                            <Icon name="user-circle" />
                                            <span>{t('app.user.PROFILE')}</span>
                                        </div>
                                    }
                                >
                                    <form className="uk-form-template">
                                        <fieldset>
                                            <legend>{t('app.user.TITLE_MAIN_SETTINGS')}</legend>
                                            <div className="uk-form-field">
                                                <Input
                                                    label={t('app.user.FULL_NAME')}
                                                    type="text"
                                                    value={`${authUser.profile.firstname} ${authUser.profile.lastname}`}
                                                    disabled
                                                />
                                            </div>
                                            <div className="uk-form-field">
                                                <Input
                                                    label={t('app.user.EMAIL')}
                                                    type="text"
                                                    value={authUser.profile.email}
                                                    disabled
                                                />
                                            </div>
                                        </fieldset>
                                    </form>
                                </div>
                                <div
                                    trigger={
                                        <div>
                                            <Icon name="lock" />
                                            <span>{t('app.user.SECURITY')}</span>
                                        </div>
                                    }
                                >
                                    <ChangePassword authUser={authUser} organization={state.organization} />
                                </div>
                            </AccTabs>
                            {process.env.REACT_APP_DEV_INFORMATIONS && authUser && (
                                <AccTabs className="margin-top-2">
                                    <div trigger="ID Token">
                                        <div className="jwt">
                                            <small style={{ wordBreak: 'break-all' }}>{authUser.id_token}</small>
                                            <div className="margin-top-1 text-center">
                                                <Button
                                                    onClick={() => copyToClipboard(authUser)}
                                                    size="small"
                                                    label={t('app.user.CTA_TOKEN')}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div trigger="Application State">
                                        <small>
                                            <pre>{JSON.stringify(state, null, 4)}</pre>
                                        </small>
                                    </div>
                                    <div trigger="OIDC State">
                                        <small>
                                            <pre>{JSON.stringify(authUser, null, 4)}</pre>
                                        </small>
                                    </div>
                                    <div trigger={t('app.user.PREFERENCES')}>
                                        <PrefPanel orgId={state.organization.id} />
                                    </div>
                                </AccTabs>
                            )}
                        </div>
                    </div>
                    <div className="grid-x align-middle align-right margin-top-1">
                        <small>v{sessionStorage.getItem(packageJson.name)}</small>
                    </div>
                </>
            </Drawer>
        </div>
    );
};

export default UserPanel;
