import React from 'react';

import { axios, useLocation, useReactOidc } from '@sm360/apps-core';

const Tracking = ({ organization, user, name, children }) => {
    const location = useLocation();
    const { oidcUser } = useReactOidc();

    React.useEffect(() => {
        let sessionId = '';
        const sendSessionEvent = (eventType, session) => {
            if (organization && organization.id && user && user.id && process.env.REACT_APP_NAME === 'kijiji') {
                const data = {
                    sessionId: session,
                    userId: user.id,
                    organizationId: organization.id,
                    timestamp: new Date().toISOString(),
                    event: eventType,
                    application: name,
                };

                const trackSessions = {
                    sessionId: session,
                    organizationId: organization.id,
                    timestamp: data.timestamp,
                };

                localStorage.setItem('trackSessions', JSON.stringify(trackSessions));
                axios
                    .post(`${process.env.REACT_APP_XMS_API_URL}/users/session`, data, {
                        headers: {
                            Authorization: `Bearer ${oidcUser.id_token}`,
                        },
                    })
                    .catch((error) => {
                        if (error.response) {
                            console.error('Duplicate entry detected, but not displayed to user');
                        } else {
                        }
                    });
            }
        };

        const checkSession = () => {
            const storedTrackSessions = localStorage.getItem('trackSessions');

            if (!storedTrackSessions) {
                const trackSessions = {
                    sessionId: `${organization.id}-${user.id}-${new Date().getTime()}`,
                    organizationId: organization.id,
                    timestamp: new Date().toISOString(),
                };
                sessionId = trackSessions.sessionId;
                localStorage.setItem('trackSessions', JSON.stringify(trackSessions));
            } else {
                const parsedTrackSessions = JSON.parse(storedTrackSessions);
                if (parsedTrackSessions.organizationId !== organization.id) {
                    const trackSessions = {
                        sessionId: `${organization.id}-${user.id}-${new Date().getTime()}`,
                        organizationId: organization.id,
                        timestamp: new Date().toISOString(),
                    };
                    sessionId = trackSessions.sessionId;
                    localStorage.setItem('trackSessions', JSON.stringify(trackSessions));
                } else {
                    sessionId = parsedTrackSessions.sessionId;
                    const currentTimestamp = new Date().toISOString();
                    const timestampDelta =
                        (new Date(currentTimestamp).getTime() - new Date(parsedTrackSessions.timestamp).getTime()) / 1000;
                    if (timestampDelta > 5) {
                        const trackSessions = {
                            sessionId: `${organization.id}-${user.id}-${new Date().getTime()}`,
                            organizationId: organization.id,
                            timestamp: currentTimestamp,
                        };
                        sessionId = trackSessions.sessionId;
                        localStorage.setItem('trackSessions', JSON.stringify(trackSessions));
                    }
                }
            }

            window.addEventListener('beforeunload', () => sendSessionEvent('end', sessionId));
            sendSessionEvent('start', sessionId);
        };

        if (organization?.id && user?.id) {
            checkSession();
        }

        return () => {
            sendSessionEvent('end', sessionId);
            window.removeEventListener('beforeunload', () => sendSessionEvent('end', sessionId));
        };
    }, [location.pathname, organization, user, name, oidcUser]);

    return children;
};

export default Tracking;
