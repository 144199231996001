import React from 'react';
import { AppContext, Loading, useTranslation } from '@sm360/apps-core';

const AppLanding = ({ children }) => {
    const {
        state: { industry },
    } = React.useContext(AppContext);
    const { locale } = useTranslation();
    return (
        <div className="uk-landing">
            <img src={`/img/logo-${industry}-landing-${locale}.svg`} alt="LogoImage" />
            {children || <Loading />}
        </div>
    );
};

export default AppLanding;
